<template>
  <section class="interest-free-loan">
    <section class="hero">
      <div class="hero-container">
        <h1>Construction de maison : le prêt à taux zéro reconduit partout en 2020</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/interest-free-loan-1.jpg"
        alt="Le prêt à taux zéro reconduit partout en 2020"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Bonne nouvelle pour les candidats à l’acquisition d’un bien neuf : le PTZ, qui devait être
          supprimé dans les territoires où le marché de l’immobilier est moins tendu qu’en
          agglomération, a finalement été maintenu pour 2020, y compris dans les zones rurales où
          son application était remise en cause.
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Dès l’été dernier, l’Etat avait fait part de son intention de réduire le périmètre du
              prêt à taux zéro, un financement exonéré d’intérêts attribué sous condition de
              ressources aux ménages qui souhaitent investir dans un bien neuf. Cette mesure,
              inscrite dans le projet de loi de Finances 2020, a finalement été retoquée par le
              Parlement : les députés et les sénateurs ont en effet adopté début décembre un
              amendement qui sauvegarde le dispositif dans ses modalités actuelles, quel que soit la
              typologie du territoire où la maison est à construire.
            </p>
            <p>
              Le projet initial rejeté l’automne dernier par les élus, visait à limiter
              l’application du PTZ aux zones dites « en tension » (B1), identifiées, dans la grande
              majorité des cas, au sein des grandes agglomérations de plus de 250 000 habitants
              marquées par un fort déséquilibre entre la demande et l’offre immobilière.
            </p>
            <p>
              Conséquence : l’aide publique devait, en contrepartie, disparaitre dans les bassins
              moins denses, ceux des villes « moyennes » et des communes rurales. Finalement, ces
              deux espaces géographiques -respectivement répertoriés en B2 et C selon la
              classification officielle- restent éligibles au prêt à taux zéro en 2020 (au moins).
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/interest-free-loan-2.jpg"
              alt="Taux zéro : limiter l’application du PTZ aux zones dites « en tension »"
            />
          </template>
        </card-type-img>
      </div>
      <div class="article-rural-region fit-content">
        <h2>En zone rurale, le PTZ finance jusqu’à 20% de l’opération</h2>
        <div class="article-rural-region-img">
          <img
            src="@/assets/img/pages/interest-free-loan-3.jpg"
            alt="En zone rurale, le PTZ finance jusqu’à 20% de l’opération"
          />
        </div>
        <div class="article-rural-region-text">
          <p>
            Les règles qui déterminent la part du montant couverte par le PTZ sont identiques à
            celles qui sont effectives depuis la réforme de 2018 : en zones B2 et C, cette prise en
            charge s’élève à 20% maximum de la valeur totale de l’opération (<router-link to="/projet-construction-maison/financement">coût de construction
            d’une maison</router-link> et, le cas échéant, celui de son terrain). Dans les grosses unités urbaines
            (B2), la quotité est doublée et grimpe à 40%. Rappelons que ce crédit gratuit vient en
            appoint d’un financement bancaire classique facturé aux taux du marché.
          </p>
          <p>
            Pour les candidats, impérativement primo-accédants, deux autres conditions sont à
            remplir: leur investissement immobilier doit porter sur un logement destiné à devenir
            leur résidence principale, et leur niveau de revenus être inférieur à des plafonds
            légaux, fixés administrativement (<a href="https://www.service-public.fr/particuliers/vosdroits/F10871">voir le barème applicable</a> en 2020 en fonction de la
            localisation du bien).
          </p>
          <p>
            Pour info, Angers (Maine-et-Loire) et Poitiers (Vienne), auparavant classés en zone B2,
            ont été requalifiés en 2019 en B1.
          </p>
          <p>
            Selon des estimations provisoires établies par la Société de gestion des Financements et
            de la Garantie de l’Accession Sociale à la propriété (SGAS), 29 000 prêts à taux zéro
            ont été accordés en France entre le 1er janvier et le 30 septembre 2019, dont 600
            environ en Anjou, 600 en Morbihan, 1 200 en Loire-Atlantique, 250 en Vienne.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'interest-free-loan',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.interest-free-loan
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-rural-region
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%
    max-width: 950px
  @media (max-width: 768px)
    .hero
      height: 25rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90vw
          height: 250px
          object-position: -1rem
          margin-top: -8rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-rural-region
        margin: 3rem auto 0 auto
        &-img
          margin-top: 2.5rem
        &-text
          margin-top: 1.5rem
</style>
